<!-- 
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : Handy Service Full App Flutter
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2024-present initappz.
 -->
<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="6">
        <span class="clearfix">
          <h1 class="float-start display-3 me-4">500</h1>
          <h4 class="pt-3">{{util.translate('Houston, we have a problem!')}}</h4>
          <p class="text-medium-emphasis float-start">
            {{util.translate('The page you are looking for is temporarily unavailable.')}}
          </p>
        </span>
        <c-input-group class="input-prepend">
          <span cInputGroupText>
            <svg cIcon name="cilMagnifyingGlass"></svg>
          </span>
          <input cFormControl placeholder="What are you looking for?" type="text" />
          <button cButton color="info">{{util.translate('Search')}}</button>
        </c-input-group>
      </c-col>
    </c-row>
  </c-container>
</div>