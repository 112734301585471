<!-- 
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : Handy Service Full App Flutter
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2024-present initappz.
 -->
<!--<c-footer>-->
<div>
  <a href="https://codecanyon.net/user/initappz/portfolio" target="_blank">Initappz</a>
  <span> &copy; {{date}} initappz</span>
</div>
<div class="ms-auto">
  {{util.translate('Powered by')}}
  <a href="https://codecanyon.net/user/initappz/portfolio" target="_blank">
    <span> Initappz</span>
  </a>
</div>
<!--</c-footer>-->