<!-- 
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : Handy Service Full App Flutter
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2024-present initappz.
 -->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="5">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm>
                <h1>{{util.translate('Login')}}</h1>
                <p class="text-medium-emphasis">{{util.translate('Sign In to your account')}}</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input cFormControl [placeholder]="util.translate('Email')" type="email" [(ngModel)]="email"
                    [class]="submited== true && email =='' ?'validator_error':''"
                    [ngModelOptions]="{standalone: true}" />
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input cFormControl [placeholder]="util.translate('Password')" type="password" [(ngModel)]="password"
                    [class]="submited== true && password =='' ?'validator_error':''"
                    [ngModelOptions]="{standalone: true}" />
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary" (click)="onLogin()">
                      {{util.translate('Login')}}
                    </button>
                  </c-col>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link" (click)="forgot()">
                      <span style="color: blue;">{{util.translate('Forgot password?')}}</span>
                    </button>
                  </c-col>
                </c-row>

                <c-row>
                  <c-col xs="6"></c-col>
                  <c-col xs="6">
                    <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="langId"
                      (change)="changeLanguages()">
                      <option [value]="item.code" *ngFor="let item of util.allLanguages"> {{item.name}} </option>
                    </select>
                  </c-col>
                </c-row>

              </form>
            </c-card-body>
          </c-card>

        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>